import React, { Fragment } from "react";
import styles from './MapView.module.css'

interface MapViewProps {
  style?: React.CSSProperties
}

const MapView: React.FunctionComponent<React.PropsWithChildren<MapViewProps>> = ({style, children}) => {
  return (
    <Fragment>
      <div id="map" className={styles['MapView']} style={style}/>
      {children}
    </Fragment>
  )
}

export default MapView
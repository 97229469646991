import { Fieldset, AutoComplete, Button, ButtonGroup, Badge } from "@geist-ui/react"
import { ChevronRight, Maximize2, Minimize2, XCircle } from "@geist-ui/react-icons";
import { Fragment, useState } from "react"
import { Category } from "./map.controller";
import { SourceItem } from "./toolbar.service";

enum ToolbarOpenState {
  OPEN,
  CLOSED
}

export interface ToolbarViewProps {
  options: Array<SourceItem>
  onSelect: (value: string) => void
  categories: Category[]
}

const getFieldsetCSSProperties = (state: ToolbarOpenState): React.CSSProperties => ({
  position: "fixed",
  top: "32px",
  left: "32px",
  maxWidth: "350px",
  maxHeight: '90vh',
  boxSizing: 'border-box',
  overflowY: 'scroll',
  transform:
    state === ToolbarOpenState.OPEN
      ? "translateY(0%)"
      : "translateY(-85%) scale(0.8)",
  transition: "all 200ms ease",
});

const ToolbarView: React.FunctionComponent<React.PropsWithChildren<ToolbarViewProps>> = ({ options, onSelect, categories }) => {
  const [toolbarState, setToolbarState] = useState(ToolbarOpenState.OPEN)
  const [myoptions, setMyOptions]: any = useState([])
  const [category, setCategory] = useState<Category | null>(null)

  const searchHandler = (currentValue: string) => {
    if (!currentValue) return setMyOptions([])
    const sanitizedValue = currentValue.toLowerCase()
    const relatedOptions = options.filter(item => item.label.toLowerCase().includes(sanitizedValue))
    const sanitizedOptions = relatedOptions.map((item) => ({ ...item, value: item.label }))
    setMyOptions(sanitizedOptions)
  }
  const toggleState: React.MouseEventHandler = () => {
    setToolbarState(
      toolbarState === ToolbarOpenState.OPEN ? ToolbarOpenState.CLOSED : ToolbarOpenState.OPEN
    )
  }
  return (
    <Fieldset style={getFieldsetCSSProperties(toolbarState)}>
      {
        category ? (
          <div style={{width: '100%', maxHeight: '400px', overflowY: 'scroll'}}>
            <Button style={{position: 'absolute', top: '16px', right: 0}} type={'abort'} icon={<XCircle/>} onClick={() => setCategory(null)} auto/>
            <Fieldset.Title style={{ marginBottom: '10px', marginRight: '16px' }}>{category.name}</Fieldset.Title>
            <ButtonGroup vertical style={{ width: '100%', marginLeft: 0, marginRight: 0 }}>
              {category.elements.map(e => (
                <Button onClick={() => onSelect(e.label)} key={e.label} scale={0.7} width={'100%'} style={{ textAlign: 'left' }} iconRight={<ChevronRight/>}>{e.label}</Button>
              ))}
            </ButtonGroup>
          </div>
        ) : (
          <Fragment>
            <Fieldset.Title>RDF Map Project</Fieldset.Title>
            <Fieldset.Subtitle>
              Добро пожаловать на платформу RDF Map. Наберите интересующий вас обьект в поле поиска ниже.
            </Fieldset.Subtitle>
            <AutoComplete marginBottom={'8px'} placeholder="Например: Иссык-куль" clearable options={myoptions} onSelect={onSelect} onSearch={searchHandler} enterKeyHint={'Поиск'} width={'100%'} />
            {categories.length > 0 && (
              <ButtonGroup vertical style={{ width: '100%' }} margin={0}>
                {categories.map(e => (
                  <Button onClick={() => setCategory(e)} key={e.name} scale={0.7} width={'100%'} style={{ textAlign: 'left', paddingLeft: '8.75px' }} iconRight={<Badge scale={0.5} type={'error'}>{e.elements.length}</Badge>}>{e.name}</Button>
                ))}
              </ButtonGroup>
            )}
          </Fragment>
        )
      }
      <Fieldset.Footer>
        2021 - RDF Map Project 1.6.1 beta
        <Button auto scale={1 / 3} type={toolbarState === ToolbarOpenState.OPEN ? "default" : "success"} iconRight={toolbarState === ToolbarOpenState.OPEN ? <Minimize2 /> : <Maximize2 />} onClick={toggleState}>
          {toolbarState === ToolbarOpenState.OPEN ? "Скрыть" : "Показать"}
        </Button>
      </Fieldset.Footer>
    </Fieldset>
  )
}

export default ToolbarView
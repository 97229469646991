export interface SourceItem {
  label: string
  value: string
  url: string
  category: string
  postURL?: string
}

export class ToolbarService {
  static async loadSources(): Promise<Array<SourceItem>> {
    try {
      const response: Response = await fetch('/polygons/sources.json')
      const result: Array<SourceItem> = await response.json()
      return result
    } catch (err) {
      console.log(err);
      return []
    }
  }
}
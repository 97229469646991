import { SourceItem } from "./toolbar.service";
import { Fieldset, Button } from '@geist-ui/react'
import { ChevronRight } from "@geist-ui/react-icons";

export interface CardViewProps {
  data: SourceItem
  onClick?: React.MouseEventHandler
}

const style: React.CSSProperties = {
  position: "fixed",
  top: "32px",
  right: "32px",
  width: "300px"
}

const CardView: React.FunctionComponent<React.PropsWithChildren<CardViewProps>> = ({ data, onClick }) => {
  return (
    <Fieldset style={style}>
      <Fieldset.Title>
        {data.label}
      </Fieldset.Title>
      <Fieldset.Subtitle>
        {data.category}
      </Fieldset.Subtitle>
      <Fieldset.Footer>
        Доступна статья
          <Button auto disabled={typeof data.postURL === 'undefined'} scale={1/3} type='success' onClick={() => window.open(data.postURL)} iconRight={<ChevronRight/>}>
            Подробнее
          </Button>
      </Fieldset.Footer>
    </Fieldset>
  )
}

export default CardView
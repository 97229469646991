// import React from "react";
import "./App.css";
// import { Map } from "./map/Map";
import MapController from "./mvc/map.controller";

function App() {
  return (
    <div className="App">
      {/* <Map
        token="pk.eyJ1IjoicGV0ZXJxbGl1IiwiYSI6ImNqdHJqdG92OTBkNTg0M3BsNDY0d3NudWcifQ.0z4ov_viFE-yBMSijQpOhQ"
        center={[74.542, 41.451]}
      /> */}
      {/* <Toolbar/> */}
      <MapController/>
    </div>
  );
}

export default App;

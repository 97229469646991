import { BBox, bbox } from '@turf/turf';
import mapboxgl, { GeoJSONSourceRaw } from 'mapbox-gl';
import randomColor from "randomcolor";

type Geojson = GeoJSONSourceRaw & { _data?: GeoJSON.Feature<GeoJSON.Geometry> | GeoJSON.FeatureCollection<GeoJSON.Geometry> | string | undefined }

export class MapService {
  private map?: mapboxgl.Map
  private lastLayer: string | null

  constructor() {
    this.lastLayer = null
  }

  configureMap(options: mapboxgl.MapboxOptions): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.map = new mapboxgl.Map(options)
        this.map.on('load', () => {
          if (!this.map) return reject('could not load the map')
          this.map.addSource("mapbox-dem", { type: "raster-dem", url: "mapbox://mapbox.mapbox-terrain-dem-v1", tileSize: 512, maxzoom: 14 })
          this.map.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 })
          this.map.addLayer({ id: "sky", type: "sky", paint: { "sky-type": "atmosphere", "sky-atmosphere-sun": [0.0, 0.0], "sky-atmosphere-sun-intensity": 15 }})
          resolve()
        })
      } catch (err) {
        reject(err)
      }
    })
  }

  private async loadSource(url: string): Promise<mapboxgl.GeoJSONSourceRaw | undefined> {
    try {
      const response: Response = await fetch(url)
      const data: string = await response.json()
      return {
        type: 'geojson',
        data: data
      }
    }
    catch (err) {
      console.log(err)
      return undefined
    }
  }

  cleanupLastLayers() {
    if (!this.map || !this.lastLayer) return
    const [ polygonLayer, lineLayer, pointLayer ] = [this.lastLayer + '-Polygon', this.lastLayer + '-Line', this.lastLayer + '-Point']
    if (this.map.getLayer(polygonLayer)) this.map.removeLayer(polygonLayer)
    if (this.map.getLayer(lineLayer)) this.map.removeLayer(lineLayer)
    if (this.map.getLayer(pointLayer)) this.map.removeLayer(pointLayer)
  }

  setLastLayer(id: string) {
    this.lastLayer = id
  }

  async setLayer(url: string): Promise<void> {
    if (!this.map) return
    try {
      let source: GeoJSONSourceRaw | undefined
      const existingSource = this.map.getSource(url) as Geojson
      if (existingSource) source = { type: 'geojson', data: existingSource._data }
      else { 
        source = await this.loadSource(url)
        if (!source) throw new Error('no such source')
        this.map.addSource(url, source)
      }
      this.cleanupLastLayers()
      this.setLastLayer(url)

      const bounds: BBox = bbox((source).data)
    if (bounds.length === 4)
      this.map?.fitBounds(bounds)
    const fillColor: string = randomColor()
    try {
      this.map?.addLayer({
        id: url + '-Polygon',
        type: 'fill',
        source: url,
        paint: {
          "fill-color": fillColor,
          "fill-opacity": 0.8,
        },
        filter: ['==', '$type', 'Polygon']
      })
    } catch (err) {
      // console.log(err);
    }
    try {
      this.map?.addLayer({
        id: url + '-Point',
        type: 'circle',
        source: url,
        paint: {
          "circle-color": fillColor,
          "circle-radius": 10
        },
        filter: ['==', '$type', 'Point']
      })
    } catch (err) {
      // console.log(err);
    }
    try {
      this.map?.addLayer({
        id: url + '-Line',
        type: 'line',
        source: url,
        paint: {
          "line-color": fillColor,
          "line-width": 4
        },
        filter: ['==', '$type', 'LineString']
      })
    } catch (err) {
      console.log(err);
    }

    } catch (err) {
      console.log('error', err);
    }
  }

  private navigateTo() {
    this.map?.flyTo({ })
  }

}
import React, { Fragment } from "react";
import CardView from "./card.view";
import { MapService } from "./map.service";
import MapView from "./map.view";
import { SourceItem, ToolbarService } from "./toolbar.service";
import ToolbarView from "./toolbar.view";

interface MapControllerProps {
}

export interface Category {
  name: string
  elements: SourceItem[]
}

interface MapControllerState {
  sourceItems: Array<SourceItem>
  selectedItem?: SourceItem
  categories: Category[]
}

class MapController extends React.Component<MapControllerProps, MapControllerState> {
  mapService: MapService

  state: MapControllerState = {
    sourceItems: [],
    categories: [],
  }


  constructor(props: MapControllerProps) {
    super(props);
    this.mapService = new MapService()
  }

  handleSelect(value: string): void {
    console.log(value);
    const selected: SourceItem | undefined = this.state.sourceItems.find(element => element.label === value)
    console.log(selected);
    if (!selected) return
    this.setState({ selectedItem: selected })
    this.mapService.setLayer(selected.url)
    const urlParams = new URLSearchParams()
    urlParams.set('nav', value)
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`)
  }

  componentDidMount() {
    this.mapService.configureMap({
      container: 'map',
      accessToken: 'pk.eyJ1IjoicGV0ZXJxbGl1IiwiYSI6ImNqdHJqdG92OTBkNTg0M3BsNDY0d3NudWcifQ.0z4ov_viFE-yBMSijQpOhQ',
      center: [74.542, 41.451],
      zoom: 7,
      style: "mapbox://styles/mapbox/satellite-streets-v11"
    })
      .then(() => {
        ToolbarService.loadSources().then(data => {
          this.getCategories(data)
          this.setState({ sourceItems: data })
          const urlParams = new URLSearchParams(window.location.search)
          const name = urlParams.get('nav')
          if (name) {
            this.handleSelect(name)
          }
        })
      })
  }

  getCategories(data: SourceItem[]) {
    const reduce: {[key: string]: Category} = {}
    data.forEach(e => {
      if (!reduce[e.category]) {
        reduce[e.category] = { name: e.category, elements: [] }
      }
      reduce[e.category].elements.push(e)
    })
    const categories = Object.values(reduce)
    console.log(categories);
    
    this.setState({ categories })
  }

  render() {
    return (
      <Fragment>
        <MapView/>
        <ToolbarView options={this.state.sourceItems} onSelect={this.handleSelect.bind(this)} categories={this.state.categories}/>
        {
          typeof this.state.selectedItem !== 'undefined' &&
          <CardView data={this.state.selectedItem}/>
        }
      </Fragment>
    )
  }
}

export default MapController